import * as React from "react";
import PageComponent from "src/components/pages/testimonials";

const Page = ({ location }) => {
  const copy = {
    testimonials: [
      {
        name: `ד”ר מיכאל גולדנהירש`,
        title: `ירושלים`,
        text: `התחלנו עם האבא והמשכנו עם הבן יונתן​ הוא המשיך את המסורת של האבא שזה אנשים מאד נחמדים, נאמנים ואפשר לסמוך עליהם.`,
        video: `https://fast.wistia.net/embed/iframe/wemokv5joz?videoFoam=true`,
      },
      {
        name: `צבי סנד`,
        title: `בית זית`,
        text: `מאד התרשמתי מהידע שלו והיכולות שלו; כל שאלה שהעלתי אליו…מיד מענה`,
        video: `https://fast.wistia.net/embed/iframe/acqnj3dnzv?videoFoam=true`,
      },
      {
        name: `צ'רלי דויטש`,
        location: `סט. לואיס, ארה"ב`,
        text: `כל אדם ה מתגורר באיזור סנט לואיס מכיר היטב את השם ״ספטנר״, זוהי מסורת של שירות ונתינה…`,
        video: `https://fast.wistia.net/embed/iframe/b7ge9zucsd?videoFoam=true`,
      },
    ],
  };
  return (
    <PageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle="המלצות"
    />
  );
};

export default Page;
