import * as React from "react";
import Layout from "src/components/layout";

const PageComponent = ({ location, copy, pageTitle, lang }) => {
  return (
    <Layout pageTitle={pageTitle} location={location} lang={lang}>
      <section className="inner-content mx-auto my-20">
        {copy.testimonials.map((item, key) => (
          <article className="w-1/2 mx-auto text-center" key={key}>
            <iframe
              title="video"
              loading="lazy"
              className="wistia_embed mx-auto w-full"
              src={item.video}
              style={{ aspectRatio: "16 / 9" }}
              allowFullScreen
            ></iframe>
            <p className="mt-4">&ldquo;{item.text}&rdquo;</p>
            <p className="font-semibold text-blue mt-2 ">{item.name}</p>
            <p className="text-blue-light mb-16">{item.title}</p>
          </article>
        ))}
      </section>
    </Layout>
  );
};

export default PageComponent;
